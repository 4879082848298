<template>
<v-container>

<h1>{{ $t('title') }}</h1>
<Table/>


</v-container>
</template>

<script>
import Table from '@/components/Table.vue';
export default {
  components: { Table },
}

</script>
<i18n>
{
  "no": {
    "title":"Nasjonalt bålplassregister"}

      }
</i18n>