<template>
<v-card>
    <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="this.$store.getters.places" :search="search" :loading="tableLoading" loading-text="Loading... Please wait">
        <template v-slot:item.now="{ item }">
            <v-chip :color="getColor(item.now)" dark>
                {{getText(item.now)}}
            </v-chip>
        </template>
        <template v-slot:item.hour1="{ item }">
            <v-chip :color="getColor(item.hour1)" dark>
                {{getText(item.hour1)}}
            </v-chip>
        </template>
        <template v-slot:item.hour2="{ item }">
            <v-chip :color="getColor(item.hour2)" dark>
                {{getText(item.hour2)}}
            </v-chip>
        </template>
        <template v-slot:item.firewood="{ item }">
            <v-chip :color="getColor(item.firewood)" dark>
                {{getText(item.firewood)}}
            </v-chip>
        </template>
    </v-data-table>
</v-card>
</template>

<script>
import axios from "axios"
export default {
    data() {
        return {
            expanded: [],
            info: null,
            tableLoading: true,
            errored: false,
            search: '',
            headers: [{
                    text: this.$t('header1'),
                    align: 'start',
                    value: 'name',
                },
                {
                    text: this.$t('header2'),
                    value: 'geo'
                },
                {
                    text: this.$t('header3'),
                    value: 'now'
                },
                {
                    text: this.$t('header4'),
                    value: 'hour1'
                },
                {
                    text: this.$t('header5'),
                    value: 'hour2'
                },
                {
                    text: this.$t('header6'),
                    value: 'firewood',
                }
            ],
        }
    },
    methods: {
        getColor(status) {
            if (status) return '#009688'
            else if (!status) return '#F44336'
            else return '#2196F3'
        },
        getText(status) {
            if (status) return 'Tommelopp'
            else if (!status) return 'Tommelned'
            else return 'Skjonner ikke'
        },
        checkWind(windSpeed, windDirection, fireplaceWind) {

            if (windSpeed <= 3) {
                return true
            } else if (3 < windSpeed < 8) {
                let wind = windDirection;
                console.log(wind);
                if (eval(fireplaceWind['good'][0])) {
                    return true
                } else if (eval(fireplaceWind['good'][1])) {
                    return true
                } else if (eval(fireplaceWind['bad'][0])) {
                    return false
                }
            } else {
                return false
            }
        }
    },
    mounted() {
        //let users = [];
        let promises = [];
        for (let firePlace of this.$store.getters.places) {
            promises.push(
                axios.get('https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=' + firePlace.lat + '&lon=' + firePlace.lon).then(response => {
                    firePlace.now = this.checkWind(response.data.properties.timeseries[0].data.instant.details.wind_speed, response.data.properties.timeseries[0].data.instant.details.wind_from_direction, firePlace.windDirection);
                    firePlace.hour1 = this.checkWind(response.data.properties.timeseries[1].data.instant.details.wind_speed, response.data.properties.timeseries[0].data.instant.details.wind_from_direction, firePlace.windDirection);
                    firePlace.hour2 = this.checkWind(response.data.properties.timeseries[2].data.instant.details.wind_speed, response.data.properties.timeseries[0].data.instant.details.wind_from_direction, firePlace.windDirection);
                    //      users.push(response);
                    console.log(response)
                }).catch(error => {
                    console.log(error)
                    this.errored = true
                })
            )
        }
        

        Promise.all(promises).then().finally(() => this.tableLoading = false);
    }
}
</script>

<i18n>
{
    "no": {
        "header1": "Navn",
        "header2": "Posisjon",
        "header3": "Status nå",
        "header4": "Om 1 time",
        "header5": "Om 2 timer",
        "header6": "Ved tilgjengelig"
      }   
}
</i18n>
