var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":this.$store.getters.places,"search":_vm.search,"loading":_vm.tableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.now",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.now),"dark":""}},[_vm._v(" "+_vm._s(_vm.getText(item.now))+" ")])]}},{key:"item.hour1",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.hour1),"dark":""}},[_vm._v(" "+_vm._s(_vm.getText(item.hour1))+" ")])]}},{key:"item.hour2",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.hour2),"dark":""}},[_vm._v(" "+_vm._s(_vm.getText(item.hour2))+" ")])]}},{key:"item.firewood",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.firewood),"dark":""}},[_vm._v(" "+_vm._s(_vm.getText(item.firewood))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }